import { Component } from '@angular/core';

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <!-- <span class="created-by">
    </span> -->
    <strong>2024 © by Bizdigital SL</strong>
    <strong>Protec3 Dashboard 1.08b</strong> 
    <!-- - &copy; 2024 Bizdigital SL. Todos los
      derechos reservados. -->
    <!-- <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div> -->
  `,
})
export class FooterComponent {}
