<nb-layout>
  <nb-layout-column>
    <nb-card>
      <!-- <nb-card-header>
        <nav class="navigation">
          <a href="#" (click)="back()" class="link" aria-label="Back"><i class="icon nb-arrow-thin-left"></i></a>
        </nav>
      </nb-card-header> -->
      <nb-card-body>
        <nb-auth-block>
          <div>
            <router-outlet></router-outlet>
          </div>
        </nb-auth-block>
      </nb-card-body>

      <!-- <nb-card-footer>
        <div class="d-flex justify-content-center">
          <div class="border shadow rounded-pill bg-light bg-gradient p-3 text-center">
            <strong>2024 © by Bizdigital SL - Protec3 Dashboard 1.03b</strong>
          </div>
        </div>
      </nb-card-footer> -->
        <!-- <div class="d-flex justify-content-center">
          <div class="border shadow rounded-pill bg-light bg-gradient p-3">
            <strong>Protec3 Dashboard 1.03b - 2024 © by Bizdigital SL</strong>
          </div>
        </div> -->
    </nb-card>
  </nb-layout-column>
</nb-layout>