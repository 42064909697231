/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

// import { MENU_ITEMS } from './pages-admin-menu';
// import { MENU_ITEMS_USER } from './pages-user-menu';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  //!
  // private menuAdminSubject = new BehaviorSubject<NbMenuItem[]>([]);
  // private menuUserSubject = new BehaviorSubject<NbMenuItem[]>([]);
  //!

  constructor(private translateService: TranslateService) {
    //!
    // this.selectMenu();
    // this.transtaleService.onLangChange.subscribe(() => {
    //   this.selectMenu();
    // });
    //!
  }

  //!
  // get MENU_ADMIN$() {
  //   return this.menuAdminSubject.asObservable();
  // }

  // get MENU_USER$() {
  //   return this.menuUserSubject.asObservable();
  // }
  //!

  get MENU_ITEMS_ADMIN(): NbMenuItem[] {
    return this.ADMIN_MENU();
  }

  get MENU_ITEMS_USER(): NbMenuItem[] {
    return this.USER_MENU();
  }

  private translateTitle(title: string): string{
    return this.translateService.instant(title);
  }

  //!
  // private selectMenu(){
  //   this.menuAdminSubject.next(this.ADMIN_MENU());
  //   this.menuUserSubject.next(this.USER_MENU());
  // }
  //!

  private ADMIN_MENU(): NbMenuItem[]{
    return [
      {
        title: this.translateTitle("pages.both_menu.dashboard.title"),
        icon: { icon: "dashboard", pack: "custom-icons" },
        // link: '/pages/dashboard',
        children: [
          {
            title: this.translateTitle("pages.both_menu.dashboard.control-panel.title"),
            link: '/pages/dashboard/control-panel',
            icon: { icon: "control-panel", pack: "custom-icons" }
          },
          {
            title: this.translateTitle("pages.both_menu.dashboard.location-map.title"),
            link: '/pages/dashboard/location-map',
            icon: 'globe-2-outline'
          },
          // {
          //   title: this.translateTitle("pages.admin_menu.maps.leaflet-dashboard.title"),
          //   link: '/pages/maps/leaflet-dashboard',
          //   icon: { icon: "dashboard", pack: "custom-icons" }
          // },
          // {
          //   title: this.translateTitle("pages.both_menu.map_children_title1"),
          //   link: '/pages/maps/leaflet',
          //   icon: 'globe-2-outline'
          // },
        ],
      },
      {
        title: this.translateTitle("pages.both_menu.infra_title"),
        icon: { icon: "infra", pack: "custom-icons" },
        children: [
          {
            title: this.translateTitle("pages.both_menu.infra_children_title1"),
            link: "/pages/infrastructure/device",
            icon: "smartphone-outline",
          },
          {
            title: "device_create",
            link: "/pages/infrastructure/device/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title5"),
            link: "/pages/infrastructure/group",
            icon: { icon: "group", pack: "custom-icons"}
          },
          {
            title: "group_create",
            link: "/pages/infrastructure/group/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title2"),
            link: "/pages/infrastructure/gateway",
            icon: { icon: "gateway", pack: "custom-icons" },
          },
          {
            title: "gateway_create",
            link: "/pages/infrastructure/gateway/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title3"),
            link: "/pages/infrastructure/user",
            icon: "people-outline"
          },
          {
            title: "user_create",
            link: "/pages/infrastructure/user/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title4"),
            link: "/pages/infrastructure/contact",
            icon: { icon: "contact", pack: "custom-icons" }
          },
          {
            title: "contact_create",
            link: "/pages/infrastructure/contact/create",
            hidden: true
          },
        ],
      },
      {
        title: this.translateTitle("pages.admin_menu.management.title"),
        icon: 'settings-2-outline',
        children: [
          // {
          //   title: this.translateTitle("pages.admin_menu.management_children_title"),
          //   link: '/pages/management/user-list',
          //   icon: 'people-outline'
          // },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title1"),
            link: '/pages/management/device',
            icon: 'smartphone-outline'
          },
          // {
          //   title: this.translateTitle("pages.both_menu.infra_children_title2"),
          //   link: "/pages/management/gateway",
          //   icon: { icon: "gateway", pack: "custom-icons" },
          // },
        ]
      },
      // {
      //   title: this.translateTitle("pages.admin_menu.events.title"),
      //   icon: { icon: "event", pack: "custom-icons" },
      //   children: [
      //     {
      //       title: this.translateTitle("pages.admin_menu.events.alarms.title"),
      //       link: '/pages/events/alarm',
      //       icon: 'clock-outline'
      //     },
      //     {
      //       title: this.translateTitle("pages.admin_menu.events.notifications.title"),
      //       link: "/pages/events/notification",
      //       icon: 'bell-outline'
      //     },
      //   ]
      // },
      // {
      //   title: "Logs",
      //   icon: { icon: "log", pack: "custom-icons" },
      //   children: [
      //     {
      //       title: "Backend Log",
      //       link: '/pages/log/backend',
      //       icon: { icon: "backend", pack: "custom-icons" }
      //     }
      //   ]
      // },
    ]
  }

  private USER_MENU(): NbMenuItem[]{
    return [
      {
        title: this.translateTitle("pages.both_menu.dashboard.title"),
        icon: { icon: "dashboard", pack: "custom-icons" },
        // link: '/pages/dashboard',
        children: [
          {
            title: this.translateTitle("pages.both_menu.dashboard.control-panel.title"),
            link: '/pages/dashboard/control-panel',
            icon: { icon: "control-panel", pack: "custom-icons" }
          },
          {
            title: this.translateTitle("pages.both_menu.dashboard.location-map.title"),
            link: '/pages/dashboard/location-map',
            icon: 'globe-2-outline'
          },
          // {
          //   title: this.translateTitle("pages.admin_menu.maps.leaflet-dashboard.title"),
          //   link: '/pages/maps/leaflet-dashboard',
          //   icon: { icon: "dashboard", pack: "custom-icons" }
          // },
          // {
          //   title: this.translateTitle("pages.both_menu.map_children_title1"),
          //   link: '/pages/maps/leaflet',
          //   icon: 'globe-2-outline'
          // },
        ],
      },
      {
        title: this.translateTitle("pages.both_menu.infra_title"),
        icon: { icon: "infra", pack: "custom-icons" },
        children: [
          {
            title: this.translateTitle("pages.both_menu.infra_children_title1"),
            link: "/pages/infrastructure/device",
            icon: "smartphone-outline"
          },
          {
            title: "device_create",
            link: "/pages/infrastructure/device/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title2"),
            link: "/pages/infrastructure/gateway",
            icon: { icon: "gateway", pack: "custom-icons" },
          },
          {
            title: "gateway_create",
            link: "/pages/infrastructure/gateway/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title5"),
            link: "/pages/infrastructure/group",
            icon: { icon: "group", pack: "custom-icons"}
          },
          {
            title: "group_create",
            link: "/pages/infrastructure/group/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title3"),
            link: "/pages/infrastructure/user",
            icon: "people-outline"
          },
          {
            title: "user_create",
            link: "/pages/infrastructure/user/create",
            hidden: true
          },
          {
            title: this.translateTitle("pages.both_menu.infra_children_title4"),
            link: "/pages/infrastructure/contact",
            icon: { icon: "contact", pack: "custom-icons" }
          },
          {
            title: "contact_create",
            link: "/pages/infrastructure/contact/create",
            hidden: true
          },
        ],
      },
    ]
  }
}
