import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NbAuthService, NbLoginComponent } from "@nebular/auth";
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '../../shared/services/auth.service';
import { BdtrService } from '../../shared/services/bdtr.service';
import { BdaService } from '../../shared/services/bda.service';


@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent extends NbLoginComponent {
  private location= inject(Location);
  private authService = inject(AuthService);
  private bdtrService = inject(BdtrService);
  private bdaService = inject(BdaService);
  private translateService = inject(TranslateService);
  router= inject(Router);

  override login(){
    const { email, password } = this.user;
    const user = { username: email, password };

    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, user)
      .pipe(
        // tap((result)=>{
        //   if(result.isSuccess()){
        //       this.bdtrService.getUserByUsername(user.username)
        //       .subscribe((result)=> {
        //         localStorage.setItem('userActive', JSON.stringify(result));
        //       })
        //   }
        // }),
        // finalize(() => {
        //   this.bdtrService.getUserByUsername(user.username)
        //     .subscribe((result) => {
        //       //delete result.id
        //       localStorage.setItem('userActive', JSON.stringify(result));
        //     })
        // })
      ).subscribe((result) => {
        //console.log(result);
        this.submitted = false;

        if (result.isSuccess()) {
          // this.messages = result.getMessages();
          this.messages = [this.translateService.instant("auth.login.ok_description")];
        } else {
          const response = result.getResponse();
          const statusCode = response.status;

          if (statusCode === 429){
            // this.errors = ["Has intentado autenticarte muy pronto. Espere 5 segundos para el siguiente intento."];
            this.errors = [this.translateService.instant("auth.general.delay")];
          } else if (statusCode === 403){
            // this.errors = ["Has alcanzado el número máximo de intentos. Tendrás que esperar 20 minutos para el próximo intento."];
            this.errors = [this.translateService.instant("auth.general.ban")];
          }else if(statusCode === 401 && response.error.message.includes("Password is expired")){  
            this.errors = [this.translateService.instant("auth.login.expired_password")];
          }else{
            // this.errors = result.getErrors();
            this.errors = [this.translateService.instant("auth.general.fail")];
          }

          console.error(response);
        }

        const redirect = result.getRedirect();
        if (redirect) {


          this.bdtrService.getUserByUsername(user.username)
          .subscribe((result)=> {
            // console.log(result);
            this.bdtrService.getOneClient(result.client_code)
            .pipe(
              catchError((err)=>{
                this.messages = [];
                this.errors = [this.translateService.instant("auth.login.client_code_error")];
                // localStorage.removeItem("auth_app_token");
                throw new HttpErrorResponse({
                  status: 404,
                  error: {
                    errorStatus: 404,
                    error: "Not Found",
                    message: `Not Found: User client ${result.client_code} doesn't exist!!`
                  }
                });
              })
            )
            .subscribe((response)=>{
              localStorage.setItem('userActive', JSON.stringify({...result, client_name: response.name}));
              // this.messages = [this.translateService.instant("auth.login.ok_description")];
            })

            setTimeout(() => {
              return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);
          })
          
          // setTimeout(() => {
          //   return this.router.navigateByUrl(redirect);
          // }, this.redirectDelay);
        }
      })
  }

  // Atributos y métodos para la visualización/ocultación del input de la contraseña
  showPassword = false;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
