<div class="container container-max-width border rounded shadow px-5 pt-5 pb-4 bg-light bg-gradient">

  <img src="assets/images/logos/protec3/protec3-logo.png" alt="logo bizdigital" class="img-fluid mx-auto d-block mb-3 w-70">
  <!-- <h1 id="title" class="title">Login</h1> -->
  <p class="sub-title">{{'auth.login.description' | translate}}</p>

  <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>{{'auth.general.error' | translate }}</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>{{'auth.login.ok' | translate}}</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>

  <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

    <div class="form-control-group">
      <label class="label fz-label" for="input-email">{{'auth.general.username' | translate}}:</label>
      <input nbInput fullWidth [(ngModel)]="user.email" #email="ngModel" name="email" id="input-email"
        pattern=".+@.+\..+" placeholder="" fieldSize="large" autofocus
        [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
        [required]="getConfigValue('forms.validation.email.required')"
        [attr.aria-invalid]="email.invalid && email.touched ? true : null">
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="caption status-danger" *ngIf="email.errors?.required">
          {{'auth.general.username_required' | translate}}
        </p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
          {{'auth.general.username_email' | translate}}
        </p>
      </ng-container>
    </div>

    <div class="form-control-group">
      <span class="label-with-link">
        <label class="label fz-label" for="input-password">{{'auth.general.password' | translate}}:</label>
        <!-- <a class="font-bold no_line text-blue-950" routerLink="/auth/request-password">¿Has olvidado la contraseña?</a> -->
      </span>
      <nb-form-field>
        <input [type]="getInputType()" nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
        id="input-password" placeholder="" fieldSize="large"
        [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
        [required]="getConfigValue('forms.validation.password.required')"
        [minlength]="getConfigValue('forms.validation.password.minLength')"
        [maxlength]="getConfigValue('forms.validation.password.maxLength')"
        [attr.aria-invalid]="password.invalid && password.touched ? true : null">
        <a nbSuffix nbButton ghost (click)="toggleShowPassword()">
          <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
                   pack="eva"
                   [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
          </nb-icon>
        </a>
      </nb-form-field>
        <a class="font-bold no_line text-blue-950 float-right mb-4" routerLink="/auth/request-password">{{'auth.login.forgot_password' | translate}}</a>
        <ng-container *ngIf="password.invalid && password.touched ">
          <p class="caption status-danger" *ngIf="password.errors?.required">
            {{'auth.general.password_required' | translate}}
          </p>
          <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
            {{'auth.general.password_minLength1' | translate}} {{ getConfigValue('forms.validation.password.minLength') }}
            {{'auth.general.password_minLength2' | translate}}
            <!-- Password should contain
              from {{ getConfigValue('forms.validation.password.minLength') }}
              to {{ getConfigValue('forms.validation.password.maxLength') }}
              characters -->
            </p>
        </ng-container>
    </div>

    <!--   <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
  </div> -->

    <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid">
      {{'auth.login.button' | translate}}
    </button>
  </form>

  <div class="d-flex justify-content-end mt-3 q">
    <span class="txt-gray">Rev. 1.0.8b</span>
  </div>
</div>
<!-- <section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  or enter with:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link" [routerLink]="socialLink.link" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url" [attr.href]="socialLink.url" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section> -->

<!-- <section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section> -->
